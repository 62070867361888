<template>
  <v-row class="profile-box">
    <v-col
      cols="12"
      class="d-flex">
      <h1 class="text-decoration-underline">
        ข้อมูลสมาชิก
      </h1>
      <v-spacer />
      <v-btn
        icon
        large
        @click="editClicked()">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      เบอร์โทร:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'tel') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ไอดี:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'id') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ชื่อ-นามสกุล:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ `${avoidNullValue(member, 'firstName', '')} ${avoidNullValue(member, 'lastName', '')}` }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      วันเกิด:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ dateCompute(member.birthDate) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      เพศ:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ genderCompute }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      อีเมล:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'email') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ที่มา:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'createdIn') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      แท็กกลุ่มลูกค้า:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      <template v-if="getMemberTags()">
        <v-chip
          v-for="(tag, index) in member.tags"
          :key="`tag-chip-${index}`"
          class="ma-1"
          color="teal"
          text-color="white"
          small
          label>
          {{ tag }}
        </v-chip>
      </template>
      <span v-else>
        -
      </span>
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ที่อยู่:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      <div v-if="avoidNullValue(member, 'addresses')">
        <div
          v-for="(address, addressIndex) in avoidNullValue(member, 'addresses')"
          :key="addressIndex">
          <span>
            {{ `${address.address} ${address.district} ${address.states} ${address.province} ${address.country} ${address.postcode}` }}
          </span>
          <v-divider class="my-2" />
        </div>
      </div>
      <div v-else>
        -
      </div>
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      คะแนนสะสม:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'point') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      วันที่เป็น Member:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ dateCompute(member.createdAt) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      วันที่เป็น VIP:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ dateCompute(member.vipDate) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ยอดตอนเป็น VIP:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(avoidNullValue(member, 'spending'), 'vip', 0) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      วันที่เป็น VVIP:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ dateCompute(member.vvipDate) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ยอดตอนเป็น VVIP:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(avoidNullValue(member, 'spending'), 'vvip', 0) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ยอดซื้อสะสม:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(avoidNullValue(member, 'spending'), 'total', 0) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ยอดซื้อปัจจุบัน:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(avoidNullValue(member, 'spending'), 'current', 0) }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      รหัสบัตรสมาชิก:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'cardId') }}
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      ยอดส่วนลดสะสม
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ calQuotaDiscount }} / 2000
    </v-col>
    <v-col
      cols="4"
      class="profile-col">
      หมายเหตุ:
    </v-col>
    <v-col
      cols="8"
      class="profile-col">
      {{ avoidNullValue(member, 'note') }}
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionProvider from '@/resources/PromotionProvider'

const PromotionService = new PromotionProvider()
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    member: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      promotionQuotaUsed: 0
    }
  },
  computed: {
    genderCompute () {
      let result = 'ไม่ระบุ'
      if (this.member.gender && this.member.gender === 'male') {
        result = 'ชาย'
      } else if (this.member.gender && this.member.gender === 'female') {
        result = 'หญิง'
      }

      return result
    },
    calQuotaDiscount () {
      if (this.member) {
        return this.promotionQuotaUsed > 0 ? this.promotionQuotaUsed : 0
      }
      return 0
    }
  },
  mounted () {
    this.getMemberDiscountQuota()
  },
  methods: {
    ...mapActions({
      setMember: 'Member/setMember'
    }),
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    async getMemberDiscountQuota () {
      try {
        const { data } = await PromotionService.getMemberCampaignUse(this.id)
        this.promotionQuotaUsed = data
      } catch (error) {
        console.error('error', error)
      }
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY') : '-'
    },
    editClicked () {
      this.setMember(this.member)

      this.$router.push({ name: 'EditMember', params: { id: this.member.id } })
    },
    getMemberTags () {
      return Boolean(this.member?.tags?.length)
    }
  }
}
</script>

<style scope>

.profile-col {
  padding: 6px 12px;
}
</style>
