<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col cols="6">
        <member-profile
          :id="+id"
          :member="member" />
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-tabs
            v-model="tabState"
            background-color="primary"
            dark>
            <v-tab>ประวัติการสั่งซื้อ</v-tab>
            <v-tab>โค้ดของลูกค้า</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabState">
            <v-tab-item>
              <v-card flat>
                <spending-log-table
                  v-model="spendingQuery"
                  :loading="spendingsLoading"
                  :items="spendingLog" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <promotions-table
                  v-model="promotionsQuery"
                  :loading="promotionsLoading"
                  :items="promotionCodes" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import PromotionProvider from '@/resources/PromotionProvider'
import MemberProfile from '../components/MemberProfile.vue'
import SpendingLogTable from '../components/SpendingLogTable.vue'
import PromotionsTable from '../components/PromotionsTable.vue'

const MemberService = new MemberProvider()
const PromotionService = new PromotionProvider()

export default {
  components: {
    MemberProfile,
    SpendingLogTable,
    PromotionsTable
  },
  data () {
    return {
      tabState: null,
      member: {},
      spendingLog: [],
      promotionCodes: [],
      spendingsLoading: false,
      promotionsLoading: false,
      spendingQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['order.createTime'],
        sortDesc: [true]
      },
      promotionsQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdBy'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    spendingQuery: {
      async handler () {
        this.spendingsLoading = true
        await this.getSpendings()
        this.spendingsLoading = false
      },
      deep: true
    },
    promotionsQuery: {
      async handler () {
        this.promotionsLoading = true
        await this.getPromotions()
        this.promotionsLoading = false
      },
      deep: true
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async getMember () {
      try {
        const { data: member } = await MemberService.getMemberById(this.$route.params.id)
        this.member = member
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-MEMBER-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getPromotions () {
      try {
        const { data: promotionCodes } = await PromotionService.getPromotionByMemberPaginate(this.$route.params.id, this.promotionsQuery)
        this.promotionCodes = promotionCodes.results
        this.promotionsQuery.total = promotionCodes.total
        this.promotionsQuery.pages = promotionCodes.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-PROMOTIONS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getSpendings () {
      try {
        const { data: spendingLog } = await MemberService.getOrdersByMemberId(this.$route.params.id, this.spendingQuery)
        this.spendingLog = spendingLog.results
        this.spendingQuery.total = spendingLog.total
        this.spendingQuery.pages = spendingLog.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-SPENDING-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async initData () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING MEMBER INFO...'
        })

        await Promise.all([
          this.getMember(),
          this.getPromotions(),
          this.getSpendings()
        ])
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
