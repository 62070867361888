<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :footer-props="dataTableFooter"
    :loading="loading">
    <template v-slot:[`item.order.createTime`]="{ item }">
      {{ dateCompute(item.order.createTime) }}
    </template>
    <template v-slot:[`item.order.net`]="{ item }">
      {{ item.order.net | showFullPriceFormat() }}
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      },
      headers: [
        { text: 'วันที่', value: 'order.createTime', width: '25%' },
        { text: 'รหัสออเดอร์', value: 'order.id' },
        { text: 'ยอดซื้อ', value: 'order.net' },
        { text: 'Code ส่วนลด', value: 'promotionCode.code' },
        { text: 'ช่องทาง', value: 'channel' },
        { text: 'สถานะ', value: 'order.currentState' }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    dateCompute (date) {
      return date ? this.$dayjs(date).add(7, 'hour').format('DD MMM YYYY | HH:mm') : '-'
    }
  }

}
</script>
