<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :footer-props="dataTableFooter"
    :loading="loading">
    <template v-slot:[`item.status`]="{ item }">
      {{ useCompute(item.history) }}
    </template>
    <template v-slot:[`item.dateRange.endDate`]="{ item }">
      {{ dateCompute(item.dateRange.endDate) }}
    </template>
    <template v-slot:[`item.memberUsedCount`]="{ item }">
      {{ historyCompute(item.history) }}
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      },
      headers: [
        { text: 'โค้ด', value: 'code' },
        { text: 'หมดอายุ', value: 'dateRange.endDate' },
        { text: 'สถานะ', value: 'status' },
        { text: 'จำนวนออเดอร์', value: 'memberUsedCount' }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY | HH:mm') : '-'
    },
    useCompute (history) {
      return history.length > 0 ? 'use' : 'unused'
    },
    historyCompute (history) {
      return history.length || 0
    }
  }
}
</script>
